<template>
  <v-container class="lighten-3 grey" text-center>
    <div class="text-center">
      <v-toolbar dense floating>
        <v-text-field
          hide-details
          prepend-icon="fas fa-search"
          single-line
          label="Buscar..."
          @input="indexData()"
          v-model="query"
        ></v-text-field>
        <v-chip class="ma-2" color="primary" text-color="white">
          {{ countProposta }} proposta(s)
        </v-chip>
        <v-chip
          class="ma-2"
          color="success"
          text-color="white"
          :to="{ path: 'comercial/formulario' }"
        >
          Novo
        </v-chip>
      </v-toolbar>
    </div>

    <v-row>
      <v-col cols="12" sm="4">
        <v-sheet rounded="lg" min-height="70vh">
          <h3>Agendar contato</h3>
          <v-row class="text-center d-flex justify-center">
            <v-progress-circular
              :size="50"
              class="mt-3"
              color="primary"
              indeterminate
              v-show="loading"
            ></v-progress-circular>
            <Item
              v-for="contato in dataTable.contato"
              :key="contato.id"
              :item="contato"
              :list="headers"
              :size="80"
            >
              <template v-slot:acoes>
                <v-btn icon color="red" @click="deleteItem(contato)">
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="primary"
                  :to="{ path: `comercial/${contato.id}/formulario` }"
                >
                  <v-icon>fa fa-edit</v-icon>
                </v-btn>
              </template>
            </Item>
          </v-row>
        </v-sheet>
      </v-col>

      <v-col cols="12" sm="4">
        <v-sheet min-height="70vh" rounded="lg">
          <h3>Proposta enviada</h3>
          <v-row class="text-center d-flex justify-center">
            <v-progress-circular
              :size="50"
              class="mt-3"
              color="primary"
              indeterminate
              v-show="loading"
            ></v-progress-circular>
            <Item
              v-for="enviado in dataTable.enviado"
              :key="enviado.id"
              :item="enviado"
              :list="headers"
              :size="80"
            >
              <template v-slot:acoes>
                <v-btn
                  icon
                  color="green"
                  :to="{ path: 'comercial/' + enviado.id }"
                >
                  <v-icon>far fa-eye</v-icon>
                </v-btn>
                <v-btn icon color="red" @click="deleteItem(enviado)">
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="primary"
                  :to="{ path: `comercial/${enviado.id}/formulario` }"
                >
                  <v-icon>fa fa-edit</v-icon>
                </v-btn>
              </template>
            </Item>
          </v-row>
        </v-sheet>
      </v-col>

      <v-col cols="12" sm="4">
        <v-sheet rounded="lg" min-height="70vh">
          <h3>Cliente fechado</h3>
          <v-row class="text-center d-flex justify-center">
            <v-progress-circular
            class="mt-3"
              :size="50"
              color="primary"
              indeterminate
              v-show="loading"
            ></v-progress-circular>
            <Item
              v-for="fechado in dataTable.fechado"
              :key="fechado.id"
              :item="fechado"
              :list="headers"
              :size="80"
            >
              <template v-slot:acoes>
                <v-btn
                  icon
                  color="green"
                  :to="{ path: 'comercial/' + fechado.id }"
                >
                  <v-icon>far fa-eye</v-icon>
                </v-btn>
                <v-btn icon color="red" @click="deleteItem(fechado)">
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="primary"
                  :to="{ path: `comercial/${fechado.id}/formulario` }"
                >
                  <v-icon>fa fa-edit</v-icon>
                </v-btn>
              </template>
            </Item>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ToastService from "@/services/ToastService";
import ComercialService from "@/services/Comercial/ComercialService";
import Item from "@/components/CardListItem/Item";
import moment from "moment";
import instanceAxios from "../../plugins/axios";
export default {
  created: async function() {
    await this.indexData();
  },

  components: {
    Item,
  },
  computed: {
    countProposta: {
      get() {
        var a = this.dataTable.fechado.length;
        var b = this.dataTable.contato.length;
        var c = this.dataTable.enviado.length;
        return a + b + c;
      },
    },
  },
  data: () => ({
    ComercialService: new ComercialService(""),
    loading: false,
    query: "",
    dataTable: {
      contato: [],
      enviado: [],
      fechado: [],
    },
    headers: [
      { title: "OPORTUNITY", nick: "Oportunidade" },
      { title: "NAME", nick: "Nome" },
      { title: "POST", nick: "Cargo" },
      { title: "EMAIL", nick: "Email" },
      { title: "PHONE", nick: "Telefone" },
      { title: "RETURN", nick: "Retorno" },
    ],
  }),

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY");
    },
  },

  methods: {
    async indexData() {
      try {
        this.loading = true;

        const res = await this.ComercialService.index(
          "commercials?search=" + this.query
        );
        this.dataTable = {
          contato: [],
          enviado: [],
          fechado: [],
        };
        res.map((element) => {
          if (element.status == "Agendar Contato") {
            this.dataTable.contato.push(element);
          } else if (element.status == "Proposta Enviada") {
            this.dataTable.enviado.push(element);
          } else if (element.status == "Cliente Fechado") {
            this.dataTable.fechado.push(element);
          }
        });
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.loading = false;
      }
    },
    countPropostas() {
      var a = this.dataTable.contato.length;
      var b = this.dataTable.enviado.length;
      var c = this.dataTable.fechado.length;
      return a + b + c;
    },

    async deleteItem(item) {
      try {
        await instanceAxios.delete(`commercials/${item.id}`);

        ToastService("Deletado com sucesso", "success");

        this.indexData();
      } catch (error) {
        ToastService("Não foi possível excluir", "error");
      }
    },
  },
};
</script>

<style></style>
