<template>
  <v-card :loading="loading" class="mx-auto my-12" :max-width="size + '%'">
    <v-row>
      <template v-for="(value, name) in item">
        <v-col
          cols="12"
          class="pt-0 pb-0"
          :key="'value' + name + 'value' + value"
          v-show="existList(name)"
        >
          <v-card-text class="ml-4" v-if="!item.type">
            <b> {{ getNick(name) }} </b> <span> {{ value }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
      </template>
      <slot name="item"></slot>
    </v-row>

    <v-card-actions class="d-flex flex-column justify-center align-center mt-4">
      <div class="text-center">
        <slot name="acoes"> </slot>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {},
    list: undefined,
    size: {
      default: 40,
    },
  },
  data: () => ({
    loading: false,
    selection: 1,
    data: {},
  }),

  methods: {
    existList(name) {
      var exist = 0;
      for (var i = 0; i < this.list.length; i++) {
        if (this.list[i].title == name.toUpperCase()) exist = 1;
      }

      return exist;
    },
    getNick(name) {
      for (var i = 0; i < this.list.length; i++) {
        if (this.list[i].title == name.toUpperCase()) return this.list[i].nick;
      }
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
  },
};
</script>
